import FetchInterceptor from "configs/FetchInterceptor";

class AdjustmentJournalServices {
  static async getAll(params = {}) {
    const apiUrl = "/core/v1/journal/adjustment/history";
    const response = await FetchInterceptor.get(apiUrl, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/journal/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/journal/adjustment";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default AdjustmentJournalServices;
