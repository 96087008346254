import FetchInterceptor from "configs/FetchInterceptor";

class PurchaseOrderServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/purchase/order/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/purchase/order/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/purchase/order/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/purchase/order/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/purchase/order/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cancel(requestBody) {
    const apiUrl = "/core/v1/purchase/order/cancel";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/order/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }

  static async getRecommendationGoodsBasedOnAverage(requestBody) {
    const apiUrl =
      "/data/v1/reporting/branch/purchases-report/recommendation/based-on-average/get/json";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async importPurchaseOrder(requestBody) {
    const apiUrl = "/core/v2/purchase-order/goods/export/get/json";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getRecommendationGoodsBasedOnAverageXlsx(requestBody) {
    const apiUrl = "/core/v2/recommendation/based-on-average/get/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }
}

export default PurchaseOrderServices;
