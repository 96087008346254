import FetchInterceptor from "configs/FetchInterceptor";

class CashReceiptsServices {
  static async getAll(params = {}, requestBody) {
    const apiUrl = "/core/v1/cash/in/history";
    const response = await FetchInterceptor.post(apiUrl, requestBody, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/cash-flow/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/cash/in";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAccountingDailyReportPdf(requestBody) {
    const apiUrl = "/core/v1/accounting-report/daily-report/pdf";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async getAccountingDailyReportXlsx(requestBody) {
    const apiUrl = "/core/v1/accounting-report/daily-report/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async getCashFlowHistoryPdf(id) {
    const apiUrl = `/core/v1/cash-flow/history/${id}/pdf`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default CashReceiptsServices;
