/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Layout, Select, Button, Divider } from "antd";
import {
  AppstoreOutlined,
  ShopOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import NavProfile from "./NavProfile";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import { useSelector } from "react-redux";
import { BranchServices } from "services";
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom";
import { setStateUser } from "redux/actions/User";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => ({
    currentUser: state.user.currentUser,
  }));

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const onChangeBranch = async (targetActiveBranch) => {
    setLoading(true);
    try {
      const respone = await BranchServices.switchBranch({ targetActiveBranch });
      const activeBranch = {
        ...currentUser,
        employee: {
          ...currentUser.employee,
          branchId: targetActiveBranch,
        },
        branch: { ...currentUser.branch, ...respone.data.currentActiveBranch },
      };
      dispatch(setStateUser({ currentUser: activeBranch }));
    } catch (error) {
      console.log(error);
    } finally {
      window.location.reload();
    }
  };

  const SelectBranch = () => {
    if (currentUser && currentUser.accessBranches && currentUser.accessBranches.length) {
      return (
        <div className="w-100 d-flex align-items-center">
          <Select
            bordered={false}
            disabled={loading}
            style={{ width: "150px" }}
            value={currentUser.employee.branchId}
            onChange={onChangeBranch}
            className="font-weight-bold"
          >
            {currentUser.accessBranches.map((item) => (
              <Select.Option value={item.id}>
                {item.isHeadBranch && <ShopOutlined className="mr-2" />}
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      );
    }
    return (
      <div className="w-100 d-flex align-items-center">
        <h5 className="mb-0">{currentUser?.branch?.name}</h5>
      </div>
    );
  };

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
            {currentUser?.company && (
              <div className="d-none d-lg-block font-weight-bold">
                <Divider type="vertical" style={{ height: "30px" }} /> {currentUser.company.name}
              </div>
            )}
          </div>
          <div className="d-flex">
            <div className="nav-right">
              {/* {window.location.pathname.includes(ADMIN_PREFIX_PATH) ? null : <SelectBranch />} */}

              {/* {currentUser.accessMenuAdmin.length && currentUser.roleAdmin ? (
                <div className="ml-3 d-none d-md-flex align-items-center justify-content-center">
                  <Button
                    block
                    className="d-flex font-weight-bold align-items-center justify-content-center"
                    type="dashed"
                    onClick={() => {
                      !window.location.pathname.includes(ADMIN_PREFIX_PATH)
                        ? history.push(ADMIN_PREFIX_PATH)
                        : history.push(APP_PREFIX_PATH);
                    }}
                  >
                    {!window.location.pathname.includes(ADMIN_PREFIX_PATH) ? (
                      <div className="d-flex align-items-center">
                        <AppstoreOutlined style={{ fontSize: "20px" }} className="mr-2" />
                        <div style={{ fontSize: "12px" }}>ADMIN</div>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <ShopOutlined style={{ fontSize: "20px" }} className="mr-2" />
                        <div style={{ fontSize: "12px" }}>CABANG</div>
                      </div>
                    )}
                  </Button>
                </div>
              ) : null}
              <div className="d-none d-md-flex align-items-center ml-3">
                <Divider type="vertical" style={{ height: "30px" }} />
              </div> */}
              <NavProfile />
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme, user }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    currentUser: user.currentUser,
  };
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);
