import FetchInterceptor from "configs/FetchInterceptor";

class DiscountServices {
  static async getAll(reqBody) {
    const apiUrl = "/core/v2/discount/product/get/all";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async getByIds(reqBody) {
    const apiUrl = "/core/v2/discount/product/get/detail";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async add(reqBody) {
    const apiUrl = "/core/v2/discount/product/create";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async update(reqBody) {
    const apiUrl = "/core/v2/discount/product/update";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async stopPromo(reqBody) {
    const apiUrl = "/core/v2/discount/product/status/update";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async getEligibility(reqBody) {
    const apiUrl = "/core/v2/discount/product/get/eligibility";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }
}

export default DiscountServices;
