import FetchInterceptor from "configs/FetchInterceptor";

class EdcServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v2/edc/list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async search(requestBody) {
    const apiUrl = "/core/v2/edc/search";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v2/edc/get/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/core/v2/edc/update/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async updateConfig(requestBody) {
    const apiUrl = "/core/v2/edc/update-config";
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v2/edc/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async simulate(requestBody) {
    const apiUrl = "/core/v2/edc/simulate";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async calculateAmountAdminMdrc(requestBody) {
    const apiUrl = "/core/v2/edc/calculate-amount-admin-mdrc";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default EdcServices;
