import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./login`))} />
        <Route
          path={`${AUTH_PREFIX_PATH}/change-password`}
          component={lazy(() => import(`./change-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/terms-condition`}
          component={lazy(() => import(`./terms`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/privacy-policy`}
          component={lazy(() => import(`./privacy`))}
        />
        <Route path={`${AUTH_PREFIX_PATH}/faq`} component={lazy(() => import(`./faq`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./login`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
