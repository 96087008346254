import FetchInterceptor from "configs/FetchInterceptor";

class DisbursementServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/banks/disbursement/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllSalesPaymentEdc(requestBody) {
    const apiUrl = "/core/v1/banks/sales-payment-edc/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async createDisbursement(requestBody) {
    const apiUrl = "/core/v1/banks/disbursement/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/banks/disbursement/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getPdf(id, requestBody) {
    const apiUrl = `/core/v1/banks/disbursement/pdf/print/${id}`;
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }
}

export default DisbursementServices;
