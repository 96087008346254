import FetchInterceptor from "configs/FetchInterceptor";

class ReceiveOrderServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/purchase/receive/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/purchase/receive/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/purchase/receive/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/receive/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default ReceiveOrderServices;
