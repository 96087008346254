import FetchInterceptor from "configs/FetchInterceptor";

class RepaymentOfReceivableServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/customer-account-payment-list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllCustomerAccount(requestBody) {
    const apiUrl = "/core/v1/sales/customer-account";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addPayment(requestBody) {
    const apiUrl = "/core/v1/sales/repayment/payment";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/sales/customer-account-payment/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/sales/repayment/download/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default RepaymentOfReceivableServices;
