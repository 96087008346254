import FetchInterceptor from "configs/FetchInterceptor";

class OpenCodServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v2/cod/booking/list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async payment(requestBody) {
    const apiUrl = "/core/v2/cod/booking/payment";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async calculate(requestBody) {
    const apiUrl = "/core/v2/cod/booking/calculate";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cancelCod(requestBody) {
    const apiUrl = "/core/v2/cod/booking/cancel";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default OpenCodServices;
