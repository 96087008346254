import FetchInterceptor from "configs/FetchInterceptor";

class StockCorrectionServices {
  static async getAll(requestBody) {
    const apiUrl = `/core/v2/corrections/get`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v2/corrections/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v2/corrections/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v2/corrections/print/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }

  static async getAllStockIn(requestBody) {
    const apiUrl = "/core/v1/stock-in/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default StockCorrectionServices;
