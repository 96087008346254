import React, { useEffect, useState } from "react";
import { APP_NAME } from "configs/AppConfig";
import { SystemPeriodServices } from "services";
import moment from "moment";

export default function Footer() {
  const [currentPeriod, setCurrentPeriod] = useState();

  useEffect(() => {
    getCurrentPeriod();
  }, []);

  const getCurrentPeriod = async () => {
    try {
      const { data } = await SystemPeriodServices.getCurrentPeriod();
      setCurrentPeriod(data);
    } catch (error) {
      console.log(error);
    }
  };

  const showPeriod = () => {
    if (currentPeriod) {
      const period = `${currentPeriod.periodYear}-${currentPeriod.periodMonth}`;
      return (
        <div>
          <a className="text-gray" href="/#" onClick={(e) => e.preventDefault()}>
            Periode {moment(period).startOf("month").format("DD MMM YYYY")} s/d{" "}
            {moment(period).endOf("month").format("DD MMM YYYY")}
          </a>
        </div>
      );
    }
  };

  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${moment().format("YYYY")}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span>
      </span>
      <div>{showPeriod()}</div>
    </footer>
  );
}
