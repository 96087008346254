import FetchInterceptor from "configs/FetchInterceptor";

class PurchaseOrderRecommendationServices {
  static async add(requestBody) {
    const apiUrl = "/core/v1/purchase/recommendation/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAll(requestBody) {
    const apiUrl = "/core/v1/purchase/recommendation/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/purchase/recommendation/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }
}

export default PurchaseOrderRecommendationServices;
