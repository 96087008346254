import { SIDE_NAV_LIGHT, DIR_LTR, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Nadi";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const ADMIN_PREFIX_PATH = "/admin";
export const OFFSET_HEADER = 70;
export const MINUS_OFFSET_TABLE_HEIGHT = 80;
export const WIDTH_COLUMN_NO_SCROLL_HORIZONTAL = 80;
export const SLACK_API = "https://slack.com";
export const SLACK_TOKEN = "xoxb-903481610593-4158374273156-xgXdhgxtVU1gzcwofvj06uev";
export const SLACK_CHANNEL_ID = "C044KPPTP8A";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "id",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#ffffff",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
