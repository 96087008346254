import FetchInterceptor from "configs/FetchInterceptor";

class ChartOfAccountService {
  static async getAll(params) {
    const apiUrl = `/accounting/coa/account${params}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getAllShortInfo(reqBody) {
    const apiUrl = "/core/v1/account/get/all";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/accounting/coa/account/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/account/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(id, requestBody) {
    const apiUrl = `/core/v1/account/update/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async getAllType(params) {
    const apiUrl = `/accounting/coa/account-type${params}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getTypeById(id) {
    const apiUrl = `/accounting/coa/account-type/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async addType(requestBody) {
    const apiUrl = "/accounting/coa/account-type";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async updateType(id, requestBody) {
    const apiUrl = `/accounting/coa/account-type/${id}`;
    const response = await FetchInterceptor.put(apiUrl, requestBody);
    return response;
  }

  static async getList(requestBody) {
    const apiUrl = `/core/v1/account/get/all`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getTypeList(requestBody) {
    const apiUrl = `/core/v1/account/type`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAccountUsage(requestBody) {
    const apiUrl = "/core/v1/account/usage/search";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default ChartOfAccountService;
