import axios from "axios";
import history from "../history";
import { Button, Modal, Result } from "antd";
import { authContants } from "views/auth-views/login/redux";
import { notification } from "antd";
import { setDefaultUser } from "redux/actions/User";
import { store } from "redux/store";
import Moment from "moment";
import { sendErrorToSlack } from "slack/errorCatch";

const FetchInterceptor = axios.create({
  timeout: 1800000, // 30 minutes
});

// Config
const ENTRY_ROUTE = "/auth/login";
const PUBLIC_REQUEST_KEY = "public-request";

//Includes Check
const addCheck = "/add";
const updateCheck = "/update";
const submitCheck = "/submit";

let checkUrlLocation = "";

// API Request interceptor
FetchInterceptor.interceptors.request.use(
  (config) => {
    const { method, url } = config;
    console.log(`Request ${method.toUpperCase()} ${url}: `, config.data);
    config.headers["timestamp"] = Moment().format("DD/MM/YYYY HH:mm");
    const activeUser = localStorage.getItem(authContants.ACTIVE_USER);
    if (activeUser) {
      config.headers["email"] = activeUser;
    }
    if (!activeUser && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({ message: "Failed", description: "Request Error" });
    return Promise.reject(error);
  },
);

console.log("checkUrlLocation", checkUrlLocation);

// API respone interceptor
FetchInterceptor.interceptors.response.use(
  (response) => {
    const { method, url } = response.config;
    console.log(`Response ${method.toUpperCase()} ${url}: `, response.data);
    return response.data;
  },
  (error) => {
    const { method, url } = error.config;
    console.log(`Response Error ${method.toUpperCase()} ${url}: `, error.response);
    checkUrlLocation = url;
    if (error.response && error.response.status && error.response.data) {
      if (error.response.status >= 500 && error.response.status < 600) {
        sendErrorToSlack(error.response);
      }
      if (error.response.status === 401) {
        Modal.destroyAll();
        localStorage.removeItem(authContants.ACTIVE_USER);
        notification.error({
          message: "Sesi Habis",
          description: "Mohon untuk login kembali!",
        });
        store.dispatch(setDefaultUser());
      } else {
        notification.error({
          message: "Failed",
          description: error.response.data.message
            ? error.response.data.message.replaceAll("_", " ")
            : error.response.statusText,
        });
      }
    } else {
      if (
        checkUrlLocation.includes(addCheck) ||
        checkUrlLocation.includes(updateCheck) ||
        checkUrlLocation.includes(submitCheck)
      ) {
        Modal.info({
          width: 700,
          centered: true,
          icon: false,
          content: (
            <div>
              <Result
                status="500"
                title="Koneksi Terputus"
                subTitle={
                  <div>
                    Apakah anda sedang menyimpan data?
                    <br />
                    Mohon dicek kembali data yg telah disimpan tadi agar tidak terjadi pengulangan
                    data yg sama!
                  </div>
                }
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      history.goBack();
                    }}
                  >
                    Kembali
                  </Button>
                }
              />
            </div>
          ),
          okButtonProps: { style: { display: "none" } },
        });
      }
      notification.error({
        message: "Permintaan Gagal Diproses",
        description: "Mohon periksa kembali koneksi jaringan Anda!",
      });
    }
    return Promise.reject(error);
  },
);

export default FetchInterceptor;
