import FetchInterceptor from "configs/FetchInterceptor";

class GoodsServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/goods/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/goods/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/goods/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/goods/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addImage(requestBody) {
    const apiUrl = "/core/v1/goods/upload/images";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async uploadImage(requestBody) {
    const apiUrl = "/core/v1/goods/upload/image";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllGoodUnits(requestBody) {
    const apiUrl = "/core/v1/goods/units/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodByIds(requestBody) {
    const apiUrl = "/core/v1/goods/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodRemainingStock(requestBody) {
    const apiUrl = "/core/v1/stock/remaining";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodUnitByIds(requestBody) {
    const apiUrl = "/core/v1/goods/units/get/data";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodUnitByIdsV2(requestBody) {
    const apiUrl = "/core/v2/goods/units/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodByBarcode(requestBody) {
    const apiUrl = "/core/v1/goods/barcode/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodsStock(requestBody) {
    const apiUrl = "/core/v1/goods/get/stock";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getBuyingPrice(requestBody) {
    const apiUrl = "/core/v1/purchase/latest/buying-price";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async updateMarginBulk(requestBody) {
    const apiUrl = "/core/v1/goods/units/margin/bulk-update-excel";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getMarginTemplateXlsx() {
    const apiUrl = "/core/v1/goods/units/update-margin-excel-template";
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }

  static async updateImage(requestBody) {
    const apiUrl = "/core/v1/goods/update/image";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async checkValidationGoods(requestBody) {
    const apiUrl = "/core/v1/goods/validation/check";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getInjectStockTemplateXlsx(requestBody) {
    const apiUrl = "/core/v1/goods/get/xlsx";
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async uploadInjectStock(requestBody) {
    const apiUrl = "/core/v1/admin/stock/batch/upload";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getGoodUploadHistory(id, requestBody) {
    const apiUrl = `/core/v1/goods/get/upload-history/${id}`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default GoodsServices;
