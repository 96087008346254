import FetchInterceptor from "configs/FetchInterceptor";

class GeneralJournalServices {
  static async getAll(params = {}, requestBody) {
    const apiUrl = "/core/v1/journal/general/history";
    const response = await FetchInterceptor.post(apiUrl, requestBody, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/journal/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/journal/general";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default GeneralJournalServices;
