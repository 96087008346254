import FetchInterceptor from "configs/FetchInterceptor";

class SalesInvoiceServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/invoices/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/sales/invoices/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = `/core/v1/sales/invoice/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllSalesInvoice(requestBody) {
    const apiUrl = "/core/v1/sales/invoice/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllSalesInvoiceById(requestBody) {
    const apiUrl = "/core/v1/sales/invoice/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/sales/invoices/print/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }

  static async getPdfWithConfig(id, requestBody) {
    const apiUrl = `/core/v1/sales/invoices/print-with-config/${id}`;
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async exportSalesFile(requestBody) {
    const apiUrl = "/core/v2/inventories/export/get/json";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SalesInvoiceServices;
