import FetchInterceptor from "configs/FetchInterceptor";

class SalesPastReturnsServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/orders/past-returns/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async getById(id) {
    const apiUrl = `/core/v1/sales/orders/past-returns/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }
  static async add(requestBody) {
    const apiUrl = "/core/v1/sales/orders/past-returns/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/sales/orders/past-returns/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async process(requestBody) {
    const apiUrl = "/core/v1/sales/orders/past-returns/process";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async printSalesPastReturn(id) {
    const apiUrl = `/core/v1/sales/orders/past-returns/get/pdf/${id}`;
    const response = await FetchInterceptor.post(apiUrl, {}, { responseType: "blob" });
    return response;
  }
}

export default SalesPastReturnsServices;
