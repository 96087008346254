import FetchInterceptor from "configs/FetchInterceptor";

class BankExpensesServices {
  static async getAll(params = {}, requestBody) {
    const apiUrl = "/core/v1/bank/out/history";
    const response = await FetchInterceptor.post(apiUrl, requestBody, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/cash-flow/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/bank/out";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default BankExpensesServices;
