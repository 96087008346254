import FetchInterceptor from "configs/FetchInterceptor";

class StockOpnameServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v2/stock-opname/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async create(requestBody) {
    const apiUrl = "/core/v2/stock-opname/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cancel(requestBody) {
    const apiUrl = "/core/v2/stock-opname/cancel";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async submit(requestBody) {
    const apiUrl = "/core/v2/stock-opname/submit";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default StockOpnameServices;
