import FetchInterceptor from "configs/FetchInterceptor";

class SupplierReceiptServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/purchase/supplier-receipt/transaction/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/purchase/supplier-receipt/transaction/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getAllHistory(requestBody) {
    const apiUrl = "/core/v1/purchase/supplier-receipt/history/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/purchase/supplier-receipt/transaction/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/supplier-receipt/transaction/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }

  static async getInvoiceStockDetail(id) {
    const apiUrl = `/core/v1/supplier/invoice/detail/stock/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  } 
}

export default SupplierReceiptServices;
