import FetchInterceptor from "configs/FetchInterceptor";

class BannerServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/banners/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async add(requestBody) {
    const apiUrl = "/core/v1/banners/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async update(requestBody) {
    const apiUrl = "/core/v1/banners/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async updateStatus(requestBody) {
    const apiUrl = "/core/v1/banners/status/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async delete(requestBody) {
    const apiUrl = "/core/v1/banners/delete";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default BannerServices;
