import FetchInterceptor from "configs/FetchInterceptor";

class HistoryOfSellingTrasactionsPeritemServices {
  static async getAll(requestBody) {
    const apiUrl = `/core/v2/sales/history/selling-price`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default HistoryOfSellingTrasactionsPeritemServices;
