import FetchInterceptor from "configs/FetchInterceptor";

class ReceivableDownPaymentServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/create";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/sales/down-payment/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/sales/down-payment/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default ReceivableDownPaymentServices;
