import FetchInterceptor from 'configs/FetchInterceptor';

class AreaServices {
  static async getAllProvinces() {
    const response = await FetchInterceptor.post('/core/v1/master/area/provinces');
    return response;
  }

  static async getAllCities(payload) {
    const response = await FetchInterceptor.post('/core/v1/master/area/cities', payload);
    return response;
  }

  static async getAllDistricts(payload) {
    const response = await FetchInterceptor.post('/core/v1/master/area/districts', payload);
    return response;
  }

  static async getAllSubDistricts(payload) {
    const response = await FetchInterceptor.post('/core/v1/master/area/sub-districts', payload);
    return response;
  }

  static async getAllZipCodes(payload) {
    const response = await FetchInterceptor.post('/core/v1/master/area/zip-codes', payload);
    return response;
  }
}

export default AreaServices;
