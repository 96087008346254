import FetchInterceptor from "configs/FetchInterceptor";

class DeliveryNotesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/delivery-notes/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/delivery-notes/get/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/delivery-notes/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async process(requestBody) {
    const apiUrl = "/core/v1/delivery-notes/update/status";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async print(id) {
    const apiUrl = `/core/v1/delivery-notes/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, { responseType: "blob" });
    return response;
  }
}

export default DeliveryNotesServices;
