import FetchInterceptor from "configs/FetchInterceptor";

class StockMutationServices {
  static async getAll(requestBody) {
    const apiUrl = `/core/v1/mutations/get/all`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/mutations/get/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async addInternalMutations(requestBody) {
    const apiUrl = "/core/v1/mutations/internal/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addExternalMutations(requestBody) {
    const apiUrl = "/core/v1/mutations/external/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async confirmExternalMutation(requestBody) {
    const apiUrl = "/core/v1/mutations/external/received";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getExternalMutationReturns(requestBody) {
    const apiUrl = "/core/v1/mutations/return/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addExternalMutationReturns(requestBody) {
    const apiUrl = "/core/v1/mutations/external/return/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async confirmExternalMutationReturns(requestBody) {
    const apiUrl = "/core/v1/mutations/external/return/received";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllStockMutation(requestBody) {
    const apiUrl = "/core/v1/mutations/stock/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/mutations/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default StockMutationServices;
