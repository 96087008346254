import FetchInterceptor from "configs/FetchInterceptor";

class CashReceiptsServices {
  static async getAll(params = {}, requestBody) {
    const apiUrl = "/core/v1/bank/in/history";
    const response = await FetchInterceptor.post(apiUrl, requestBody, { params });
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/cash-flow/history/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/bank/in";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default CashReceiptsServices;
