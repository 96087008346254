import FetchInterceptor from "configs/FetchInterceptor";

class PointCorrectionServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/correction/point/all/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/correction/point/submit";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getByIds(requestBody) {
    const apiUrl = "/core/v1/correction/point/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default PointCorrectionServices;
