import { SET_STATE_USER, SET_DEFAULT_USER } from "redux/constants/User";

const initState = {
  currentUser: null,
  accessMenus: [],
  accessMenuAdmin: [],
};

export const user = (state = initState, action) => {
  switch (action.type) {
    case SET_STATE_USER:
      return {
        ...state,
        ...action.payload,
        accessMenus: action.payload.currentUser.accessMenus,
        accessMenuAdmin: action.payload.currentUser.accessMenuAdmin,
      };
    case SET_DEFAULT_USER:
      return initState;
    default:
      return state;
  }
};
