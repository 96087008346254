import axios from "axios";
import { SLACK_API, SLACK_CHANNEL_ID, SLACK_TOKEN } from "configs/AppConfig";

const jsonBeauty = (json) => JSON.stringify(json, null, "\t");

export const sendErrorToSlack = async (response) => {
  try {
    const rootStorage = localStorage.getItem("persist:root");
    const currentUser = JSON.parse(JSON.parse(rootStorage).user).currentUser;
    const token = currentUser?.refreshToken?.token;
    const email = currentUser?.employee?.email;

    let responseError = response?.data;
    let headerError = response?.headers;
    let bodyError = response?.config.data;
    let errorURL = response?.config.url;
    let errorMethod = response?.config?.method;
    let fileType = "json";
    let fileName = `error ${errorURL}`;
    let endPoint = {
      method: errorMethod.toUpperCase(),
      beUrl: errorURL,
      feUrl: window.location.href,
    };

    let initial_comment = `Method : ${errorMethod.toUpperCase()}\nBE-URL : ${errorURL}\nFE-URL : ${
      window.location.href
    }`;
    let title = `Status ${response?.status} : ${response?.data?.message}`;

    let content = `${jsonBeauty({
      endPoint,
      header: headerError,
      body: JSON.parse(bodyError),
      response: {
        statusCode: response?.status,
        ...responseError,
      },
      user: {
        email,
        token,
      },
    })}`;

    let headerReq = new Headers();
    headerReq.append("Content-Type", "application/x-www-form-urlencoded");
    headerReq.append("Bearer", SLACK_TOKEN);

    let urlencoded = new URLSearchParams();
    urlencoded.append("token", SLACK_TOKEN);
    urlencoded.append("content", content);
    urlencoded.append("filetype", fileType);
    urlencoded.append("filename", fileName);
    urlencoded.append("title", title);
    urlencoded.append("initial_comment", initial_comment);
    urlencoded.append("channels", SLACK_CHANNEL_ID);

    await axios.post(`${SLACK_API}/api/files.upload`, urlencoded, {
      headers: headerReq,
    });
  } catch (error) {
    console.log("sendErrorToSlack", error);
  }
};
