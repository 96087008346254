import FetchInterceptor from "configs/FetchInterceptor";

class PromotionServices {
  static async getAll(reqBody) {
    const apiUrl = "/core/v2/discount/promo/get/all";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async getByIds(reqBody) {
    const apiUrl = "/core/v2/discount/promo/get/detail";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async add(reqBody) {
    const apiUrl = "/core/v2/discount/promo/create";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async update(reqBody) {
    const apiUrl = "/core/v2/discount/promo/update";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async stopPromo(reqBody) {
    const apiUrl = "/core/v2/discount/promo/status/update";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }

  static async getEligibility(reqBody) {
    const apiUrl = "/core/v2/discount/promo/get/eligibility";
    const response = await FetchInterceptor.post(apiUrl, reqBody);
    return response;
  }
}

export default PromotionServices;
