import FetchInterceptor from "configs/FetchInterceptor";

class SalesReturnsServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllByCustomerId(id, requestBody) {
    const apiUrl = `/core/v1/sales/orders/returns/get/all/${id}`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/sales/orders/returns/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async process(requestBody) {
    const apiUrl = "/core/v1/sales/orders/returns/process";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async printSalesReturn(id) {
    const apiUrl = `/core/v1/sales/orders/returns/get/pdf/${id}`;
    const response = await FetchInterceptor.post(apiUrl, {}, { responseType: "blob" });
    return response;
  }
}

export default SalesReturnsServices;
