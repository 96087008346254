import FetchInterceptor from 'configs/FetchInterceptor';

class BankServices {
  static async getAllBank(payload) {
    const response = await FetchInterceptor.post('/core/v1/banks/get/all', payload);
    return response;
  }
}

export default BankServices;
