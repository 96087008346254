import FetchInterceptor from "configs/FetchInterceptor";

class RedeemPointServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/redeem/point/all/get";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async add(requestBody) {
    const apiUrl = "/core/v1/redeem/point/submit";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async getById(requestBody) {
    const apiUrl = `/core/v1/redeem/point/get`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default RedeemPointServices;
