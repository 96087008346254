import FetchInterceptor from "configs/FetchInterceptor";

class SalesReturnsServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v1/sales/orders/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/orders/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/sales/orders/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/sales/orders/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async availabilityCheck(requestBody) {
    const apiUrl = "/core/v1/goods/availability/check";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async update(requestBody) {
    const apiUrl = "/core/v1/sales/orders/update";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async process(requestBody) {
    const apiUrl = "/core/v1/sales/orders/process";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async print(requestBody) {
    const apiUrl = "/core/v1/sales/orders/print";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async simulatesPoint(requestBody) {
    const apiUrl = "/core/v1/sales/orders/simulate";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPdf(id, requestBody) {
    const apiUrl = `/core/v1/sale/booking/get/pdf/${id}`;
    const response = await FetchInterceptor.post(apiUrl, requestBody, {
      responseType: "blob",
    });
    return response;
  }

  static async printSalesOrder(id) {
    const apiUrl = `/core/v1/sales/orders/get/pdf/${id}`;
    const response = await FetchInterceptor.post(apiUrl, {}, { responseType: "blob" });
    return response;
  }

  static async calculateAfterDiscount(requestBody) {
    const apiUrl = `/core/v1/sales/orders/calculate-after-discount`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SalesReturnsServices;
