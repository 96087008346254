import FetchInterceptor from "configs/FetchInterceptor";

class BranchServices {
  static async getAllBranch(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/get/all", payload);
    return response;
  }

  static async getBranch(payload) {
    const response = await FetchInterceptor.get(`/core/v1/branches/get/${payload}`);
    return response;
  }

  static async getAllBankAccount(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/bank-account/get/all", payload);
    return response;
  }

  static async addBranch(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/add", payload);
    return response;
  }

  static async updateBranch(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/update", payload);
    return response;
  }

  static async addBankAccount(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/bank-account/add", payload);
    return response;
  }

  static async updateBankAccount(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/bank-account/update", payload);
    return response;
  }

  static async updateGreetingMessage(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/message/update", payload);
    return response;
  }

  static async uploadLogo(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/logo/upload", payload);
    return response;
  }

  static async getLogo(payload) {
    const response = await FetchInterceptor.post("/core/v1/branches/logo/get", payload);
    return response;
  }

  static async switchBranch(payload) {
    const response = await FetchInterceptor.post("/core/v1/switch/branch", payload);
    return response;
  }

  static async resetBranch(payload) {
    const response = await FetchInterceptor.post("/core/v1/reset/branch", payload);
    return response;
  }

  static async getByIds(payload) {
    const response = await FetchInterceptor.post("/core/v1/branch/get/detail", payload);
    return response;
  }
}

export default BranchServices;
