import FetchInterceptor from "configs/FetchInterceptor";

class SystemPeriodServices {
  static async getCurrentPeriod() {
    const apiUrl = "/core/v1/period";
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getAllPeriod(requestBody) {
    const apiUrl = "/core/v1/period/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async openJournalPosting(requestBody) {
    const apiUrl = "/core/v1/period/posting/open";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async closeJournalPosting(requestBody) {
    const apiUrl = "/core/v1/period/posting/close";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addOpenPeriod(requestBody) {
    const apiUrl = "/core/v1/period/open";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addClosePeriod(requestBody) {
    const apiUrl = "/core/v1/period/close";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async restorePeriod(requestBody) {
    const apiUrl = "/core/v1/period/restore";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default SystemPeriodServices;
