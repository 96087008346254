/* eslint-disable array-callback-return */
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { useSelector } from "react-redux";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import _ from "lodash";

export const AdminViews = () => {
  const { accessMenuAdmin } = useSelector((state) => state.user);

  const listMenu = [
    {
      menu: "sidenav.system.admin",
      path: `${ADMIN_PREFIX_PATH}/system`,
      component: lazy(() => import(`./system`)),
    },
    {
      menu: "sidenav.master.admin",
      path: `${ADMIN_PREFIX_PATH}/master`,
      component: lazy(() => import(`./master`)),
    },
  ];

  const menus = _.chain(listMenu)
    .filter((item) => {
      const findMenu = _.find(accessMenuAdmin, (item2) => item2.menu === item.menu);
      if (findMenu) {
        return item;
      }
    })
    .map((item) => {
      const findMenu = _.find(accessMenuAdmin, (item2) => item2.menu === item.menu);
      return { ...item, subMenu: findMenu.subMenu };
    })
    .value();

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${ADMIN_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`../app-views/dashboard`))}
        />
        {menus.length &&
          menus.map(({ path, component: ComponentWrapper, subMenu }) => (
            <Route path={path}>
              <ComponentWrapper subMenu={subMenu} />
            </Route>
          ))}
        <Route
          path={`${ADMIN_PREFIX_PATH}/404`}
          component={lazy(() => import(`../auth-views/page-not-found`))}
        />
        <Redirect from={`${ADMIN_PREFIX_PATH}`} to={`${ADMIN_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AdminViews);
