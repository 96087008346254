import FetchInterceptor from "configs/FetchInterceptor";

class SalesNotesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/notes/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addDebit(requestBody) {
    const apiUrl = `/core/v1/sales/notes/debit/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addCredit(requestBody) {
    const apiUrl = `/core/v1/sales/notes/credit/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/sales/notes/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/sales/note/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default SalesNotesServices;
